import colors from 'vuetify/es5/util/colors';

export default {
  width: { lg: 1200, xl: 1200, md: 900 },
  'grid-padding': {
    xs: 2,
    sm: 4,
    md: 8,
    lg: 16,
    xl: 24,
  },
  primary: {
    base: '#34E2D9',
    lighten1: '#5DE8E1',
    lighten2: '#85EEE8',
    lighten3: '#AEF3F0',
    lighten4: '#D6F9F7',
  },
  secondary: {
    base: '#FF5A5A',
    lighten1: '#FF5E5E',
    lighten2: '#FF6B6B',
    lighten3: '#FF8C8C',
    lighten4: '#FFCDCD',
  },
  tertiary: {
    base: '#FFF200',
    lighten1: '#FFF64D',
    lighten2: '#FFF87F',
    lighten3: '#FFFBB2',
    lighten4: '#FFFEE5',
  },
  background: {
    base: '#edeeef',
    lighten1: '#f2f2f2',
    lighten2: '#f4f5f6',
    lighten3: '#f8f9fa',
    lighten4: '#fcfcfc',
    lighten5: '#fff',
    darken3: '#343a40',
    darken5: '#000',
  },
  darkblue: '#16445F',
  lightblue: '#d6f9f7',
  success: colors.green.lighten2,
  error: colors.red,
  warning: colors.amber,
  info: colors.lightBlue.lighten3,
};
