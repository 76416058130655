import { getPartnerDetailsResult } from '@homeserve/od-typings/api';
import {
  address,
  blacklisted,
  customer,
  customerIp,
  date,
  deployment,
  domain,
  form,
  job,
  order,
  postCode,
  product,
  productList,
  reason,
  review,
  seo,
  trader,
  traderAvailability,
  transaction,
  tree,
  voucher,
  OrderState,
  TreeState,
  AddressState,
  BlacklistedState,
  CustomerState,
  ICustomerIpState,
  IDateState,
  IVoucherState,
  ReviewState,
  FormState,
  IProductState,
  DomainState,
  PostCodeState,
  ISeoState,
  IJobState,
  ITransactionState,
  IDeploymentState,
  IReasonState,
  IProductListState,
  TraderAvailabilityState,
  ITraderState,
} from '@homeserve/vue-api-plugin';
import { auth } from '@homeserve/vue-auth-plugin';
import {
  breadcrumb,
  diagnostic,
  order as orderApp,
  DiagnosticState,
  BreadcrumbState,
  OrderState as OrderAppState,
} from '@homeserve/vue-components-plugin/src/main';
import cloneDeep from 'lodash/cloneDeep';
import Vue from 'vue';
import Vuex, { ActionContext } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import { version } from '../package.json';
import { AuthState } from '@homeserve/vue-auth-plugin/types/store/modules/auth';

Vue.use(Vuex);

type AppContext = ActionContext<AppState, AppState>;

export interface AppState {
  api: {
    tree: TreeState | Record<string, never>;
    address: AddressState | Record<string, never>;
    customer: {
      blacklisted: BlacklistedState | Record<string, never>;
      customer: CustomerState | Record<string, never>;
    };
    customerIp: ICustomerIpState | Record<string, never>;
    date: IDateState | Record<string, never>;
    voucher: { voucher: IVoucherState | Record<string, never> };
    review: {
      review: ReviewState | Record<string, never>;
      form: FormState | Record<string, never>;
    };
    catalog: {
      product: IProductState | Record<string, never>;
      domain: DomainState | Record<string, never>;
      postCode: PostCodeState | Record<string, never>;
      seo: ISeoState | Record<string, never>;
      productList: IProductListState | Record<string, never>;
    };
    order: {
      order: OrderState | Record<string, never>;
      job: IJobState | Record<string, never>;
      transaction: ITransactionState | Record<string, never>;
      deployment: IDeploymentState | Record<string, never>;
      reason: IReasonState | Record<string, never>;
    };
    trader: {
      traderAvailability: TraderAvailabilityState | Record<string, never>;
      trader: ITraderState | Record<string, never>;
    };
  };
  app: {
    diagnostic: DiagnosticState | Record<string, never>;
    order: OrderAppState | Record<string, never>;
    breadcrumb: BreadcrumbState | Record<string, never>;
  };
  auth: AuthState | Record<string, never>;
  isBusinessBuild: boolean;
  partner: getPartnerDetailsResult;
  query: { [key: string]: string | number | boolean };
  stickyFooterHeight: number;
}

const state: AppState = {
  api: {
    tree: {},
    address: {},
    customer: {
      blacklisted: {},
      customer: {},
    },
    customerIp: {},
    date: {},
    order: { order: {}, reason: {}, deployment: {}, transaction: {}, job: {} },
    voucher: { voucher: {} },
    trader: { trader: {}, traderAvailability: {} },
    catalog: {
      productList: {},
      seo: {},
      postCode: {},
      domain: {},
      product: {},
    },
    review: { review: {}, form: {} },
  },
  auth: {},
  app: { diagnostic: {}, order: {}, breadcrumb: {} },
  isBusinessBuild: false,
  partner: PARTNER_CONFIG,
  query: {},
  stickyFooterHeight: 0,
};

const getters = {
  stickyFooterHeight(s: AppState) {
    return s.stickyFooterHeight;
  },
  isBusinessBuild(s: AppState) {
    return s.isBusinessBuild;
  },
  partner(s: AppState): getPartnerDetailsResult {
    return s.partner;
  },
  query(s: AppState) {
    return s.query;
  },
};

const mutations = {
  query(s: AppState, payload: AppState['query']) {
    s.query = { ...payload };
  },
  stickyFooterHeight(s: AppState, height: AppState['stickyFooterHeight']) {
    s.stickyFooterHeight = height;
  },
  partner(s: AppState, partner: AppState['partner']) {
    s.partner = partner;
  },
};

const actions = {
  setQuery(context: AppContext, query: AppState['query']) {
    context.commit('query', query);
  },
  setStickyFooterHeight(context: AppContext, height: AppState['stickyFooterHeight']) {
    context.commit('stickyFooterHeight', height);
  },
  setPartner(context: AppContext, partner: AppState['partner']) {
    context.commit('partner', partner);
  },
};

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      key: `${process.env.VUE_APP_LOCALSTORAGE_KEY}_${version}`,
      reducer(reducerState) {
        const clonedState = cloneDeep(reducerState);
        delete clonedState.app.order.followOrderEvents;
        delete clonedState.auth.failed;
        delete clonedState.api.customer.customer.doesCustomerExist;
        delete clonedState.api.order.deployment.deployment;
        delete clonedState.isBusinessBuild;
        delete clonedState.app.order.shouldCustomerValidateOrder;
        delete clonedState.stickyFooterHeight;
        delete clonedState.partner;

        return clonedState;
      },
    }),
  ],
  state,
  mutations,
  actions,
  getters,
  modules: {
    auth,
    api: {
      namespaced: true,
      modules: {
        tree,
        address,
        // customer,
        customerIp,
        date,
        voucher: {
          namespaced: true,
          modules: {
            voucher,
          },
        },
        review: {
          namespaced: true,
          modules: {
            review,
            form,
          },
        },
        catalog: {
          namespaced: true,
          modules: {
            product,
            domain,
            postCode,
            seo,
            productList,
          },
        },
        customer: {
          namespaced: true,
          modules: {
            blacklisted,
            customer,
          },
        },
        trader: {
          namespaced: true,
          modules: {
            traderAvailability,
            trader,
          },
        },
        order: {
          namespaced: true,
          modules: {
            order,
            job,
            transaction,
            deployment,
            reason,
          },
        },
      },
    },
    app: {
      namespaced: true,
      modules: { diagnostic, breadcrumb, order: orderApp },
    },
  },
  strict: true,
});
