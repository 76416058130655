
import Header from '@/components/Header.vue';
import { CustomerUser, CustomerUserType, IDomain, getPartnerDetailsResult } from '@homeserve/od-typings/api';
import { authHelper } from '@homeserve/vue-auth-plugin';
import WLBrand from '@homeserve/vue-components-plugin/src/components/WLBrand.vue';
import WLHeader from '@homeserve/vue-components-plugin/src/components/WLHeader/WLHeader.vue';
import { formatPhone } from '@homeserve/vue-components-plugin/src/utils/phoneHelper';
import cssVars from 'css-vars-ponyfill';
import { LocaleMessage } from 'vue-i18n';
import { Component, Vue } from 'vue-property-decorator';
import { Getter, State } from 'vuex-class';

@Component({
  components: {
    WLAuthFailed: () => import('@homeserve/vue-components-plugin/src/components/WLError/WLAuthFailed.vue'),
    WLHeader,
    WLBrand,
    Footer: () => import('@/components/Footer.vue'),
    Header,
  },
})
export default class Home extends Vue {
  get cssEnable() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (window.customProp) {
      return false;
    } else {
      return true;
    }
  }

  get isOurServiceLanding() {
    return this.$route.meta && this.$route.meta.layout && this.$route.meta.layout === 'ourService';
  }

  get isHomeConversion() {
    return this.$route.name === 'home-conversion';
  }

  @Getter('stickyFooterHeight')
  protected stickyFooterHeight!: number;

  @Getter('api/customer/user')
  private user!: CustomerUser;

  get navLinks(): any {
    const isUserAuthenticated = authHelper.isUserAuthenticated(this.$store);
    const isBusinessBuild = this.$store.state.isBusinessBuild;

    const routes: any[] = [
      ...this.domains.map((domain, index) => ({
        routeName: 'diagnostic',
        params: {
          domain: this.$te(`domains.slugs.${domain.code}`) ? this.$t(`domains.slugs.${domain.code}`) : domain.slug,
        },
        label: `${domain.name.toLowerCase()}`,
        title: `${this.$t('homepage.repair')} ${domain.name.toLowerCase()}`,
        wrapperClasses: index === this.domains.length - 1 ? ['domain-link', 'domain-link-last'] : ['domain-link'],
      })),
    ];

    if (!isBusinessBuild) {
      routes.push({
        routeName: 'followOrder',
        label: this.$t('navbar.order_tracking_button'),
        classes: ['cta-order-tracking', 'bold'],
      });
    }

    if (isBusinessBuild && isUserAuthenticated) {
      // if you push an array, you get a dropdown menu

      const connectedRoutes = [
        {
          routeName: 'profil',
          label: this.$t('navbar.profil'),
          classes: ['text-uppercase'],
        },
        {
          routeName: 'logout',
          label: this.$t('navbar.logout'),
          classes: ['text-uppercase'],
        },
      ];

      if (this.user && this.user.type !== CustomerUserType.AFFILIATE) {
        connectedRoutes.splice(1, 0, {
          routeName: 'history',
          label: this.$t('navbar.history'),
          classes: ['text-uppercase'],
        });

        this.$router.addRoutes([
          {
            name: 'history',
            path: '/history',
            component: () => import(/* webpackChunkName: "callback" */ './containers/History.vue'),
            meta: {
              title: 'History',
            },
          },
        ]);
      }
      routes.push(connectedRoutes);
    }

    return routes;
  }

  get topNavLinks(): { href?: string; url?: string; label: LocaleMessage }[] {
    return [
      {
        href: 'http://www.homeserve.fr',
        label: this.$t('navbar.homeserve_link'),
      },
      {
        url: '/qui-sommes-nous',
        label: this.$t('navbar.who_we_are_link'),
      },
      {
        url: '/aide',
        label: this.$t('navbar.help_link'),
      },
    ];
  }
  @State('failed', { namespace: 'auth' })
  private authFailed!: boolean;

  @State('domains', { namespace: 'api/catalog/domain' })
  private domains!: IDomain[];

  @State('partner')
  private partner!: getPartnerDetailsResult;

  get supportPhone() {
    return this.isHomeConversion ? '170965063' : this.partner.supportPhone;
  }

  private i18n: any;

  private created() {
    this.i18n = {
      header: {
        navNumberFees: this.$t('navbar.phone_fees'),
      },
      authFailedTitle: this.$t('authFailed.title'),
    };
  }

  get navNumber() {
    return formatPhone(this.supportPhone, '0', true);
  }

  get phoneNumberLink() {
    return formatPhone(this.supportPhone, 'tel:+33', false);
  }

  private mounted() {
    cssVars();
  }
}
