import { CustomerUserType, getPartnerDetailsResult } from '@homeserve/od-typings/api';
import { customerHelper } from '@homeserve/vue-api-plugin';
import { authHelper } from '@homeserve/vue-auth-plugin';
import { breadcrumbHelper, orderHelper as orderHelperApp } from '@homeserve/vue-components-plugin/src/main';
import omit from 'lodash/omit';
import moment from 'moment';
import Router, { Route, RouteConfig } from 'vue-router';
import { checkAuth } from './http-client';
import i18n from './lang';
import store from './store';
import { Dictionary } from 'vue-router/types/router';
import { TranslateResult } from 'vue-i18n';

const homeComponent =
  process.env.VUE_APP_DEFAULT_HOME && process.env.VUE_APP_DEFAULT_HOME === 'oddJobs'
    ? () => import(/* webpackChunkName: "home.route" */ './containers/HomeOddJobs.vue')
    : () => import(/* webpackChunkName: "home.route" */ './containers/Home.vue');

const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'home',
    component: homeComponent,
    meta: {
      gtm: i18n.t('homepage.page_name'),
      title: "Dépannage d'urgence & travaux à la demande - MesBonsPros par HomeServe",
      metaTags: [
        {
          name: 'description',
          content:
            "MesBonsPros : offre de dépannage à la demande par HomeServe. Réalisez un devis gratuit en 2min pour l'intervention d'un professionnel de confiance en plomberie, électricité, gaz, serrurerie ou électroménager. Plus de 124 000 dépannages chaque année. 1 million de clients. Depuis 15 ans.",
        },
      ],
    },
  },
  {
    path: '/notre-service',
    name: 'our-service',
    component: () => import(/* webpackChunkName: "ourservice.route" */ './containers/OurService.vue'),
    meta: {
      title: 'Notre service - MesBonsPros',
      layout: 'ourService',
    },
  },
  {
    path: '/embedded-form',
    name: 'embedded-form',
    component: () => import(/* webpackChunkName: "embeddedform.route" */ './containers/EmbeddedForm.vue'),
    meta: {
      noFooter: true,
      noHeader: true,
    },
  },
  {
    path: '/qui-sommes-nous',
    name: 'whoAreWe',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about.route" */ './containers/WhoAreWe.vue'),
    meta: {
      gtm: i18n.t('who_are_we_page.page_name'),
      title: 'Qui sommes-nous ? - MesBonsPros',
    },
  },
  {
    path: '/autodiagnostic/:domain',
    name: 'diagnostic',
    component: () => import(/* webpackChunkName: "diagnostic.route" */ './containers/Diagnostic.vue'),
    meta: (route: { params: { domain: any } }) => {
      const meta: {
        gtm: TranslateResult;
        title: string;
        metaTags: Array<{
          name: string;
          content: string;
        }>;
      } = {
        gtm: i18n.t('diagnosis.page_name'),
        title: 'Diagnostiquer un problème - MesBonsPros',
        metaTags: [
          {
            name: 'robots',
            content: 'index, follow',
          },
        ],
      };

      switch (route.params.domain) {
        case 'plomberie':
          meta.title = `Dépannage d'urgence en plomberie par MesBonsPros`;
          meta.metaTags.push({
            name: 'description',
            content: `Vous avez besoin d'un dépannage, d'une réparation ou de réaliser de petits travaux en plomberie ? Effectuez un diagnostic et obtenez un devis avec MesBonsPros.`,
          });
          break;
        case 'electricite':
          meta.title = `Dépannage d'urgence en électricité par MesBonsPros`;
          meta.metaTags.push({
            name: 'description',
            content:
              'Vous souhaitez réaliser une réparation, un dépannage ou des petits travaux en électricité ? Effectuez un diagnostic et obtenez un devis avec MesBonsPros.',
          });
          break;
        case 'electromenager':
          meta.title = 'Réparation électroménager à domicile par MesBonsPros';
          meta.metaTags.push({
            name: 'description',
            content: `Vous êtes à la recherche d'un réparateur en électroménager pour un dépannage rapide sur l'un de vos appareils ? Effectuez un diagnostic avec MesBonsPros.`,
          });
          break;
        case 'gaz':
          meta.title = `Dépannage d'urgence gaz : nos interventions - MesBonsPros`;
          meta.metaTags.push({
            name: 'description',
            content: `Vous souhaitez faire réparer une fuite de gaz ou simplement faire remplacer un flexible ? Réalisez un diagnostic et obtenez un devis avec MesBonsPros.`,
          });
          break;
        case 'serrurerie':
          meta.title = 'Dépannage porte claquée ou verrouillée : réalisez un diagnostic - MesBonsPros';
          meta.metaTags.push({
            name: 'description',
            content: `Votre porte est claquée ou verrouillée et vous vous retrouvez enfermé dehors ? Rendez-vous sur MesBonsPros pour réaliser un diagnostic et obtenir un devis.`,
          });
          break;
        case 'nuisibles':
          meta.title = 'Solutions anti-nuisibles : faites appel à MesBonsPros !';
          meta.metaTags.push({
            name: 'description',
            content: `Faites appel à nos experts pour trouver la solution anti-nuisibles adaptée à vos besoins : dératisation, désinsectisation (rampants et volants)...`,
          });
          break;
      }

      return meta;
    },
    children: [
      {
        path: ':slug(.*[^/])',
        meta: {
          metaTags: [
            {
              name: 'robots',
              content: 'noindex, follow',
            },
          ],
        },
      },
    ],
  },
  {
    path: '/produit/:slug',
    name: 'productPage',
    component: () => import(/* webpackChunkName: "product.route" */ './containers/Product.vue'),
    meta: {
      gtm: i18n.t('product_page.page_name'),
    },
  },
  {
    path: '/recommandation',
    name: 'repudiation',
    component: () => import(/* webpackChunkName: "repudiation.route" */ './containers/Repudiation.vue'),
    meta: {
      gtm: i18n.t('repudiation_page.page_name'),
      title: 'Page Répudiation - MesBonsPros',
    },
  },
  {
    path: '/tunnel/precisions',
    name: 'fixAppointment',
    component: () => import(/* webpackChunkName: "appointment.route" */ './containers/FixAppointment.vue'),
    meta: {
      gtm: i18n.t('fix_appointment_page.page_name'),
      title: 'Prise de rendez-vous - MesBonsPros',
      description: 'Précisez votre problème et choisissez le créneau d’intervention pour votre dépannage',
    },
  },
  {
    path: '/tunnel',
    name: ' tunnel',
    redirect: { name: 'informations' },
    component: () => import(/* webpackChunkName: "tunnel.route" */ './containers/Tunnel.vue'),
    meta: {
      gtm: i18n.t('tunnel_page.page_name'),
      title: 'Prise de rendez-vous - MesBonsPros',
    },
    children: [
      {
        path: 'informations',
        name: 'informations',
        props: { name_site: process.env.VUE_APP_PARTNER_LABEL },
        component: () =>
          import(
            /* webpackChunkName: "tunnel.infos.route" */ '@homeserve/vue-components-plugin/src/components/WLTunnel/WLInformations.vue'
          ),
        meta: {
          gtm: i18n.t('tunnel_page.info_section.page_name'),
          title: 'Infos personnelles - MesBonsPros',
          description: 'Renseignez vos informations personnelles pour votre dépannage',
        },
      },
      {
        path: 'recapitulatif',
        name: 'recapitulatif',
        component: () =>
          import(
            /* webpackChunkName: "tunnel.summary.route" */ '@homeserve/vue-components-plugin/src/components/WLTunnel/WLSummary.vue'
          ),
        meta: {
          gtm: i18n.t('tunnel_page.summary_section.page_name'),
          title: 'Récapitulatif du dépannage - MesBonsPros',
          description: 'Récapitulatif avant réservation de votre  dépannage en plomberie, électricité, chauffage',
        },
      },
      {
        path: 'confirmation',
        name: 'confirmation',
        component: () =>
          import(
            /* webpackChunkName: "tunnel.confirm.route" */ '@homeserve/vue-components-plugin/src/components/WLTunnel/WLConfirmation.vue'
          ),
        meta: { gtm: i18n.t('tunnel_page.confirmation_section.page_name') },
        props: {
          icon: require('@/assets/check-big.svg'),
        },
      },
      {
        path: 'fin',
        name: 'end',
        component: () =>
          import(
            /* webpackChunkName: "tunnel.end.route" */ '@homeserve/vue-components-plugin/src/components/WLTunnel/WLEnd.vue'
          ),
        meta: { gtm: i18n.t('tunnel_page.end_section.page_name') },
      },
    ],
  },
  {
    name: 'followOrderLogin',
    path: '/suivre-votre-commande',
    component: () => import(/* webpackChunkName: "followorder.login.route" */ './containers/FollowOrderLogin.vue'),
    meta: {
      gtm: i18n.t('follow_order_login_page.page_name'),
      title: 'Suivi de commande - MesBonsPros',
    },
  },
  {
    name: 'followOrder',
    path: '/suivi',
    component: () => import(/* webpackChunkName: "followorder.route" */ './containers/FollowOrder.vue'),
    meta: {
      gtm: i18n.t('follow_order.page_name'),
      title: 'Suivi de commande - MesBonsPros',
    },
  },
  {
    name: 'followOrderTrackingUUID',
    path: '/suivi/:trackingUUID',
    component: () =>
      import(/* webpackChunkName: "followorder.uuid.route" */ './containers/FollowOrderTrackingUUID.vue'),
  },
  {
    name: 'evaluationTrackingUUID',
    path: '/evaluation/:trackingUUID',
    component: () => import(/* webpackChunkName: "review.uuid.route" */ './containers/ReviewTrader.vue'),
    meta: {
      gtm: i18n.t('review_trader.page_name'),
      title: 'Evaluer mon professionnel - MesBonsPros',
    },
  },
  {
    name: 'frequentlyAskedQuestions',
    path: '/aide',
    component: () => import(/* webpackChunkName: "faq.route" */ './containers/FrequentlyAskedQuestions.vue'),
    meta: {
      gtm: i18n.t('faq_page.page_name'),
      title: 'Foire aux questions - MesBonsPros',
    },
  },
  {
    name: 'ourRates',
    path: '/nos-tarifs',
    component: () => import(/* webpackChunkName: "ourRates.route" */ './containers/OurRates.vue'),
    meta: {
      gtm: i18n.t('our_rates_page.page_name'),
      title: 'Nos tarifs de dépannage à domicile à la demande - MesBonsPros',
      metaTags: [
        {
          name: 'description',
          content: `Vous êtes à la recherche d'un tarif de dépannage en plomberie, électricité, gaz ou serrurerie ? Faites confiance à HomeServe et son service de dépannage à la demande au meilleur prix !`,
        },
      ],
    },
  },
  {
    name: 'ourRatesByDomain',
    path: '/nos-tarifs/depannage-:domain',
    component: () => import(/* webpackChunkName: "ourRates.route" */ './containers/OurRatesByDomain.vue'),
    meta: (route: { params: { domain: any } }) => {
      const meta: {
        title: string;
        metaTags: Array<{
          name: string;
          content: string;
        }>;
      } = {
        title: '',
        metaTags: [],
      };
      switch (route.params.domain) {
        case 'plomberie':
          meta.title = 'Nos tarifs en dépannage plomberie – MesBonsPros';
          meta.metaTags.push({
            name: 'description',
            content: `Consultez les prix de nos plombiers professionnels pour un dépannage d’urgence ou des petits travaux. Nos prestataires se déplacent à votre domicile 7j/7 24h/24.`,
          });
          break;
        case 'electricite':
          meta.title = 'Quel est le prix d’un dépannage électrique ? – MesBonsPros';
          meta.metaTags.push({
            name: 'description',
            content:
              'Retrouvez ci-dessous l’ensemble de nos tarifs pour un dépannage électrique d’urgence. Vous pouvez également faire appel à nos électriciens agrées pour de petits travaux.',
          });
          break;
        case 'gaz':
          meta.title = 'Combien coûte un dépannage en gaz ? - MesBonsPros';
          meta.metaTags.push({
            name: 'description',
            content: `Vous avez une fuite de gaz chez vous ? Un robinet ou un flexible de gaz à changer ? Faites appel à MesBonsPros pour bénéficier d'une intervention rapide.`,
          });
          break;
        case 'serrurerie':
          meta.title = 'Nos tarifs de dépannage en serrurerie – MesBonsPros';
          meta.metaTags.push({
            name: 'description',
            content: `Le prix d'un serrurier peut varier selon le jour de son intervention (férié, week-end). Nos serruriers vous accompagnent pour l'ouverture de porte claquée ou verrouillée.`,
          });
          break;
        case 'electromenager':
          meta.title = `Nos tarifs pour la réparation d'électroménager – MesBonsPros`;
          meta.metaTags.push({
            name: 'description',
            content: `Découvrez les coûts de réparation de vos appareils électroménagers par les experts HomeServe. Les professionnels de notre réseau interviennent à votre domicile 7j/7 24h/24.`,
          });
          break;
        case 'nuisibles':
          meta.title = `Les prix de nos interventions anti-nuisibles - MesBonsPros`;
          meta.metaTags.push({
            name: 'description',
            content: `Découvrez les tarifs des prestations anti-nuisibles réalisées par MesBonsPros. Nous intervenons en dératisation, désinsectisation et destruction de nids.`,
          });
          break;
      }
      return meta;
    },
  },
  {
    name: 'conditionsOfUse',
    path: '/conditions-generales-d-utilisation',
    component: () => import(/* webpackChunkName: "conditionsOfUse" */ './containers/ConditionsOfUse.vue'),
    meta: {
      gtm: i18n.t('conditions_of_use_page.page_name'),
      title: `Conditions générales d'utilisation - MesBonsPros`,
    },
  },
  {
    name: 'termsAndConditions',
    path: '/conditions-generales-de-vente',
    component: () => import(/* webpackChunkName: "termsAndConditions" */ './containers/TermsAndConditions.vue'),
    meta: {
      gtm: i18n.t('terms_and_conditions_page.page_name'),
      title: 'Conditions générales de vente - MesBonsPros',
    },
  },
  {
    name: 'legalNotices',
    path: '/mentions-legales',
    component: () => import(/* webpackChunkName: "legalNotices" */ './containers/LegalNotices.vue'),
    meta: {
      gtm: i18n.t('legal_notices_page.page_name'),
      title: 'Mentions légales - MesBonsPros',
    },
  },
  {
    name: 'privacyPolicy',
    path: '/politique-de-donnees-personnelles',
    component: () => {
      window.location.href = 'https://www.homeserve.fr/politique-protection-donnees-personnelles?lien_interne=OD';
    },
  },
  {
    path: '/politique-de-confidentialite',
    component: () => {
      router.push({ name: 'privacyPolicy' });
    },
  },
  {
    name: 'cookiesPolicy',
    path: '/politique-des-cookies',
    component: () => {
      window.location.href = 'https://www.homeserve.fr/politique-des-cookies?lien_interne=OD';
    },
  },
  {
    name: 'ourPartners',
    path: '/nos-partenaires',
    component: () => import(/* webpackChunkName: "partners.route" */ './containers/OurPartners.vue'),
    meta: {
      gtm: i18n.t('our_partners.page_name'),
      title: 'Nos partenaires - MesBonsPros',
    },
  },
  {
    name: 'landingParis',
    path: '/plombier-paris-idf',
    component: () => import('./containers/LandingPages/SEACampaign.vue'),
    meta: {
      title: 'Commandez votre plombier sur Paris - Ile-de-France - MesBonsPros',
      metaTags: [
        {
          name: 'description',
          content: `Diagnostiquez votre dépannage plomberie en 2 minutes et commandez en ligne l'intervention d'un plombier qualifié sur Paris et toute la région Île-de-France. Le groupe HomeServe : plus de 20 ans dans le dépannage`,
        },
      ],
      landing: true,
    },
  },
  {
    name: 'landingBordeaux',
    path: '/plombier-bordeaux-33',
    component: () => import('./containers/LandingPages/SEACampaign.vue'),
    meta: {
      title: 'Commandez votre plombier sur Bordeaux et sa région - MesBonsPros',
      metaTags: [
        {
          name: 'description',
          content: `Diagnostiquez votre dépannage plomberie en 2 minutes et commandez en ligne l’intervention d’un plombier qualifié sur Bordeaux et sa région. Le groupe HomeServe : plus de 20 ans dans le dépannage`,
        },
      ],
      landing: true,
    },
  },
  {
    name: 'landingToulouse',
    path: '/plombier-toulouse-31',
    component: () => import('./containers/LandingPages/SEACampaign.vue'),
    meta: {
      title: 'Commandez votre plombier sur Toulouse et sa région - MesBonsPros',
      metaTags: [
        {
          name: 'description',
          content: `Diagnostiquez votre dépannage plomberie en 2 minutes et commandez en ligne l’intervention d’un plombier qualifié sur Toulouse et sa région. Le groupe HomeServe : plus de 20 ans dans le dépannage`,
        },
      ],
      landing: true,
    },
  },
  {
    name: 'landingGeneral',
    path: '/plombier-depannage',
    component: () => import('./containers/LandingPages/SEACampaign.vue'),
    meta: {
      title: 'Commandez votre dépannage plombier - MesBonsPros',
      metaTags: [
        {
          name: 'description',
          content: `Diagnostiquez votre dépannage plomberie en 2 minutes et commandez en ligne l'intervention d'un plombier qualifié proche de chez vous. Le groupe HomeServe : plus de 20 ans dans le dépannage`,
        },
      ],
      landing: true,
    },
  },
  {
    name: 'landingLeak',
    path: '/fuite-eau-plombier',
    component: () => import('./containers/LandingPages/SEACampaign.vue'),
    meta: {
      title: 'Une fuite d’eau ? Commandez votre réparation fuite - MesBonsPros',
      metaTags: [
        {
          name: 'description',
          content: `Une fuite d’eau, une recherche de fuite, un robinet qui fuit ? Commandez un dépannage en plomberie. Le groupe HomeServe : plus de 20 ans dans le dépannage`,
        },
      ],
      landing: true,
    },
  },
  {
    name: 'landingClogged',
    path: '/debouchage-canalisation',
    component: () => import('./containers/LandingPages/SEACampaign.vue'),
    meta: {
      title: 'Une canalisation ou des WC bouchés ? Commandez votre débouchage - MesBonsPros',
      metaTags: [
        {
          name: 'description',
          content: `Une canalisation ou un WC bouché ? Commandez votre débouchage en plomberie. Le groupe HomeServe : plus de 20 ans dans le dépannage`,
        },
      ],
      landing: true,
    },
  },
  {
    name: 'fourOfour',
    path: '/404',
    component: () => import(/* webpackChunkName: "fourOfour" */ './containers/FourOfour.vue'),
    meta: {
      gtm: i18n.t('fourOfour.page_name'),
      title: 'Page inexistante - MesBonsPros',
    },
  },
  {
    path: '/alexa',
    redirect: {
      name: 'home',
      query: {
        utm_campaign: 'Recommandation_Alexa',
        utm_medium: 'Amazon',
        utm_source: 'VoiceBot',
      },
    },
  },
  {
    name: 'veoliaCollab',
    path: '/VeoliaCollab',
    caseSensitive: false,
    redirect: {
      name: 'home',
      query: {
        utm_source: 'VEOLIA',
        utm_medium: 'partnerships',
        utm_campaign: 'OffesCollab',
        affiliationCode: '4eeae6e0-124a-11ec-88e9-09f3cbd515b8',
      },
    },
  },
  {
    name: 'veoliaCollabQR',
    path: '/VeoliaCollabQR',
    caseSensitive: false,
    redirect: {
      name: 'home',
      query: {
        utm_source: 'VEOLIA',
        utm_medium: 'partnerships',
        utm_campaign: 'OffesCollabQR',
        affiliationCode: '4eeae6e0-124a-11ec-88e9-09f3cbd515b8',
      },
    },
  },
  {
    path: '*',
    redirect: '/404',
  },
];

if (store.state.isBusinessBuild) {
  routes.unshift(
    {
      name: 'login',
      path: '/login',
      component: () => import(/* webpackChunkName: "login" */ './containers/Login.vue'),
      meta: {
        gtm: i18n.t('login.page_name'),
        title: 'Login',
      },
    },
    {
      name: 'logout',
      path: '/logout',
      component: () => import(/* webpackChunkName: "login" */ './containers/Logout.vue'),
      meta: {
        gtm: i18n.t('logout.page_name'),
        title: 'Logout',
      },
    },
    {
      name: 'callback',
      path: '/callback',
      component: () => import(/* webpackChunkName: "callback" */ './containers/Callback.vue'),
      meta: {
        title: 'Callback',
      },
    },
    {
      name: 'profil',
      path: '/profil',
      component: () => import(/* webpackChunkName: "profil.route" */ './containers/Profil.vue'),
      meta: {
        title: i18n.t('navbar.profil'),
      },
    },
  );
}

const router = new Router({
  mode: 'history',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

function hasQuery(query: Record<string, unknown>) {
  return !!Object.keys(query).length;
}

function cleanQuery(query: Dictionary<string | (string | null)[]>) {
  return omit(query, ['postCode', 'success', 'trackingUUID', 'gclid']);
}

async function handleAffiliationCodeQueryParam(query: Route['query']) {
  const isBusinessMode = store.getters.isBusinessBuild;
  const user = store.getters['api/customer/user'];

  if (isBusinessMode && user && user.type !== CustomerUserType.AFFILIATE) {
    return;
  }

  const affiliationCode = query.affiliationCode;

  if (affiliationCode && typeof affiliationCode === 'string') {
    const { error, result: affiliate } = await customerHelper.fetchAffiliate(store, affiliationCode);

    if (error || !affiliate) {
      return;
    }

    await orderHelperApp.setAffiliation(store, {
      code: affiliationCode,
      date: new Date(),
      email: affiliate.email,
    });

    if (process.env.VUE_APP_AFFILIATE_PHONE_NUMBERS) {
      const affiliatesPhonesNumbers = JSON.parse(process.env.VUE_APP_AFFILIATE_PHONE_NUMBERS);
      const localAffiliate = affiliatesPhonesNumbers.find(
        (a: { partnerId: string }) => a.partnerId === affiliate.partnerId,
      );
      if (!localAffiliate || !localAffiliate.phoneNumber) {
        return;
      }
      const partner: getPartnerDetailsResult = store.getters.partner;
      store.dispatch('setPartner', {
        ...partner,
        supportPhone: localAffiliate.phoneNumber,
      });
    }
  }
}

router.beforeEach(async (to, from, next) => {
  await checkAuth();
  breadcrumbHelper.clear(store);

  if (store.state.isBusinessBuild) {
    let isUserAuthenticated = authHelper.isUserAuthenticated(store);
    const future = moment().add(30, 'minute').toDate().getTime();
    const tokenWillExpired = store.getters['auth/tokenWillExpired'];
    const expiredInLessThan30Minutes = tokenWillExpired(future);

    if (authHelper.isUserAuthenticated(store) && expiredInLessThan30Minutes) {
      await authHelper.refreshToken(store);
      isUserAuthenticated = authHelper.isUserAuthenticated(store);
    }
    if (!isUserAuthenticated && to.name !== 'login' && to.name !== 'callback') {
      return next('/login');
    }

    if (isUserAuthenticated && to.name === 'login') {
      return next('/');
    }
  }

  if (!from.name && !from.matched.length) {
    await handleAffiliationCodeQueryParam(to.query);
  }

  if (hasQuery(from.query) && !hasQuery(to.query)) {
    let { query } = from;
    query = cleanQuery(query);
    store.commit('query', { ...query });

    return hasQuery(query)
      ? next({
          name: to.name || undefined,
          params: to.params,
          query,
        })
      : next();
  }

  if (hasQuery(to.query)) {
    const query = store.getters.query;
    store.commit('query', { ...query, ...to.query });
  }

  // This goes through the matched routes from last to first, finding the closest route with meta.
  let nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find(r => r.meta);

  if (nearestWithMeta && typeof nearestWithMeta.meta === 'function') {
    const meta = nearestWithMeta.meta(to);
    if (meta) {
      nearestWithMeta = { ...nearestWithMeta, meta };
    }
  }

  // If a title was found, set the document (page) title to that value.
  if (nearestWithMeta?.meta.title) {
    document.title = nearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode?.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (nearestWithMeta && !nearestWithMeta.meta.metaTags) {
    return next();
  }

  // Turn the meta tag definitions into actual elements in the head.
  if (nearestWithMeta) {
    nearestWithMeta.meta.metaTags
      .map((tagDef: { [x: string]: string }) => {
        const tag = document.createElement('meta');

        Object.keys(tagDef).forEach(key => {
          tag.setAttribute(key, tagDef[key]);
        });

        // We use this to track which meta tags we create, so we don't interfere with other ones.
        tag.setAttribute('data-vue-router-controlled', '');

        return tag;
      })
      // Add the meta tags to the document head.
      .forEach((tag: any) => document.head.appendChild(tag));
  }

  next();
});

export default router;
